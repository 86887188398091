


































import Vue from 'vue';

export default Vue.extend({
  name: 'UnauthLayout',
  data() {
    return {
      otp: '',
    };
  },
  methods: {
    otpFinishHandle(input: string): void {
      if (input === '5430') {
        console.log('Correct pin! SET USER  STATE TO AUTHORIZED STATE');
        this.$store.commit('setLoginState', true);
      } else {
        this.$toast.error('Incorrect pin provided');
      }
      // Clear input field
      this.otp = '';
    },
  },
});
