/**
        THIS IS EXAMPLE DATA USED FOR INTERNAL REFERENCING

 * {
  cycle_count: 2560,
  concentration: 0,
  id: 0,
  temperature: 25.079999923706055,
  pressure: 100.40167236328125,
  humidity_relative: 40.6220703125,
  humidity_absolute: 9.37592887878418
}
 */

export interface MPSSensorData {
  cmdId: string; // hex
  status: string; // hex
  length: string; // hex
  cksum: string; // hex
  payload: {
    cycleCount: number;
    concentration: number;
    id: number;
    temperature: number;
    pressure: number;
    humidityRelative: number;
    humidityAbsolute: number;
    detectedGas?: string;
  };
  timestamp?: string;
}

// eslint-disable-next-line no-shadow
export enum SENSOR_STATUS {
  OK = '00',
  WARMUP = '27',
  GENERIC_ERROR = 'EMIT',
}

/**
 * This lookup table is derived from the information provided in the "Flammable Gas Sensor 4.0 User Manual"
 * @param id The ID of the detected gas as provided by the sensor
 * @returns The description (or name) of the detected gas
 */
export function lookupDetectedGas(id: number): string {
  switch (id) {
    case 0:
      return 'No Gas';
    case 1:
      return 'Hydrogen';
    case 2:
      return 'Hydrogen Mixture';
    case 3:
      return 'Methane';
    case 4:
      return 'Light Gas';
    case 5:
      return 'Medium Gas';
    case 6:
      return 'Heavy Gas';
    case 253:
      return 'Unknown Gas';
    case 254:
      return 'Under Range'; // - Concentration less than -5% LEL
    case 255:
      return 'Over Range'; // - Concentration greater than 100% LEL
    default:
      return 'Unknown Gas';
  }
}

export function lookupStatusCode(status: string): SENSOR_STATUS {
  switch (status) {
    case '00':
      return SENSOR_STATUS.OK;
    case '27':
      return SENSOR_STATUS.WARMUP;
    default:
      return SENSOR_STATUS.GENERIC_ERROR;
  }
}

/**
 * Parse the sensor data from the raw object received from the API into the MPSSensorData interface
 * @param rawData The body object as received from the backend
 * @returns The MPSSensorData object
 */
export function parseMPSSensorData(rawData: any): MPSSensorData {
  const data: MPSSensorData = {
    cmdId: rawData.data.cmdID,
    status: rawData.data.status,
    length: rawData.data.length,
    cksum: rawData.data.cksum,
    payload: {
      cycleCount: rawData.data.payload.cycle_count,
      concentration:
        rawData.data.payload.concentration === 0
          ? 0
          : +(rawData.data.payload.concentration as number).toFixed(1),
      id: rawData.data.payload.id,
      temperature: +(rawData.data.payload.temperature as number).toFixed(1),
      pressure: +(rawData.data.payload.pressure as number).toFixed(1),
      humidityRelative: +(rawData.data.payload.humidity_relative as number).toFixed(1),
      humidityAbsolute: +(rawData.data.payload.humidity_absolute as number).toFixed(1),
      detectedGas: lookupDetectedGas(rawData.data.payload.id),
    },
    timestamp: `${rawData.current_timestamp.split('T')[1].substring(0, 8)} UTC (cloud)`,
  };

  return data;
}
