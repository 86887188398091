/* eslint-disable import/extensions */
import * as THREE from 'three';

import { VRButton } from '@/util/VRButton.js';
import { Text } from 'troika-three-text';
import SphericalPlayer from './streaming/SphericalPlayer';
import OverlayPlayer from './streaming/OverlayPlayer';
import AntMediaPlayer from './streaming/AntMediaPlayer';
import {
  MPSSensorData,
  SENSOR_STATUS,
  lookupDetectedGas,
  lookupStatusCode,
  parseMPSSensorData,
} from './mps-sensor-helper';

/**
 * The ThreeXR class is the base class for the VR Application.
 * a requirement for the ThreeXR app to function properly is that a valid
 * HTML container should be provided on which the WebGLRenderer can be mounted.
 *
 */
export default class ThreeXR {
  // === THREE elements ===
  private scene: THREE.Scene;

  private renderer: THREE.WebGLRenderer;

  private camera: THREE.PerspectiveCamera;

  private text: THREE.Object3D | undefined;

  // === WebRTC player ===
  private spherePlayer: AntMediaPlayer;

  private thermalPlayer: AntMediaPlayer;

  /* ============================================================================
     ============================== CONSTRUCTOR =================================
     ============================================================================
  */
  constructor() {
    // Fetch and assign DOM elements
    const sphereVideoElement = document.getElementById('sphere') as HTMLVideoElement;
    const thermalVideoElement = document.getElementById('thermal-overlay') as HTMLVideoElement;
    const videoLoaderElement = document.getElementById('video_loader') as HTMLElement;

    // === Instantiate App ===
    // Instantiate THREE.JS vars
    this.scene = new THREE.Scene();
    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.camera = new THREE.PerspectiveCamera(
      70,
      window.innerWidth / window.innerHeight,
      0.01,
      2000
    );

    // Instantiate videoplayers
    // TODO: Settings in future releasescreen?
    this.spherePlayer = new SphericalPlayer(
      this.scene,
      sphereVideoElement,
      'media.emit-it.com',
      5443, // or 5080 for insecure
      'theta360',
      '',
      true,
      videoLoaderElement
    );

    this.thermalPlayer = new OverlayPlayer(
      this.scene,
      thermalVideoElement,
      'media.emit-it.com',
      5443, // or 5080 for insecure
      'thermal',
      '',
      true
    );

    // Test data
    this.fillText();

    // Instantiate THREE Scene & rendering loop
    this.initXR();
    this.animate();
  }

  private async fillText() {
    const myText = new Text();
    this.scene.add(myText);

    // Set properties to configure:
    myText.text = `
      LEL: 0%
      Detected: None
    `;
    myText.fontSize = 0.1;
    myText.position.set(-0, -0, -2);
    myText.color = 0xffffff;
    // myText.colorRanges = { 0: 0xffffff, 11: 0xff0000, 15: 0xffffff, 31: 0xff0000 };
    myText.outlineWidth = '10%';
    myText.outlineColor = 0x000000;

    // Update the rendering:
    myText.sync();
    this.text = myText;

    setInterval(async () => {
      const data = await this.requestLatestSensorData();

      if (!data) {
        myText.text = `
          MPS sensor ERROR
          - Contact support -
          `;
      } else {
        const sensorStatus = lookupStatusCode(data.status);

        if (sensorStatus === SENSOR_STATUS.OK) {
          myText.text = `
            LEL: ${data.payload.concentration}%
            Detected: ${lookupDetectedGas(data.payload.id)}
            Cycles: ${data.payload.cycleCount}
          `;
        } else if (sensorStatus === SENSOR_STATUS.WARMUP) {
          myText.text = `
            Sensor warming up..
            Cycles: ${data.payload.cycleCount}/104
          `;
        } else {
          myText.text = `
          MPS sensor ERROR
          - Contact support -
          `;
        }
      }
      // myText.colorRanges = { 0: 0xffffff, 11: 0xff0000, 15: 0xffffff, 31: 0xff0000 };
    }, 1500);
  }

  /* ============================================================================
     ============================== PUBLIC METHODS ==============================
     ============================================================================
  */
  public initXR(): void {
    // === Instantiate THREE.JS scene ===
    // THREE.js binding
    this.scene.background = new THREE.Color(0x101010);

    // === WebGLRenderer Init ===
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.renderer.xr.enabled = true;
    // See docs https://developer.mozilla.org/en-US/docs/Web/API/XRSession/requestReferenceSpace
    this.renderer.xr.setReferenceSpaceType('local');
    const container = document.getElementById('container') as HTMLDivElement;
    container.appendChild(this.renderer.domElement);
    // Attach button to switch to VR mode.
    document.body.appendChild(VRButton.createButton(this.renderer));

    // === Resize event listener ===
    window.addEventListener('resize', this.onWindowResize.bind(this));
  }

  /**
   * animate()
   * Let the WebGLRenderer render whenever it is able to render.
   */
  public animate(): void {
    this.renderer.setAnimationLoop(this.render.bind(this));
  }

  /* ============================================================================
     ============================= PRIVATE  METHODS =============================
     ============================================================================
  */
  private onWindowResize() {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  }

  /**
   * render()
   * When called will render all child entities relevant to the
   * provided render-camera.
   */
  private render() {
    // Move the text according to the camera movement
    const targetPosition = new THREE.Vector3();
    // const distanceFromCamera = this.text.position.distanceTo(this.camera.position);
    const distanceFromCamera = 2;
    const cameraDirection = new THREE.Vector3();

    this.camera.getWorldDirection(cameraDirection);
    targetPosition
      .copy(this.camera.position)
      .add(cameraDirection.multiplyScalar(distanceFromCamera));
    targetPosition.y -= 0.7;
    targetPosition.x += 0.6;

    if (this.text) {
      this.text.position.lerp(targetPosition, 0.3);
      this.text.quaternion.copy(this.camera.quaternion);
    }

    this.renderer.render(this.scene, this.camera);
  }

  /**
   * Requests the latest available sensor data of the flammable MPS sensor from the Bork backend
   * @returns The latest sensor data
   */
  private async requestLatestSensorData(): Promise<MPSSensorData | undefined> {
    const API_TOKEN = process.env.VUE_APP_API_TOKEN;

    if (!API_TOKEN) {
      console.warn(
        "No API token was specified in the environment variable! Can't query sensor data!"
      );
      return undefined;
    }

    const data = await (
      await fetch('https://bas.backend.emit-it.com/mps/latest', {
        headers: {
          Authorization: `Bearer ${API_TOKEN}`,
        },
        mode: 'cors',
        credentials: 'include',
      })
    ).json();

    const parsedData = parseMPSSensorData(data);
    console.log(parsedData);

    return parsedData;
  }
}
