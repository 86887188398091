import Vue from 'vue';
import Vuex from 'vuex';

import { AppState } from '@/util/interfaces';

Vue.use(Vuex);

export default new Vuex.Store<AppState>({
  state: {
    isLoggedIn: false,
  },
  getters: {
    getLoggedState(state) {
      return state.isLoggedIn;
    },
  },
  mutations: {
    setLoginState(state, newState: boolean) {
      state.isLoggedIn = newState;
    },
  },
  actions: {},
  modules: {},
});
