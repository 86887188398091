import Vue from 'vue';
import '@babel/polyfill';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import { PluginOptions } from 'vue-toastification/dist/types/src/types/index.d';

import adapter from 'webrtc-adapter';
import vuetify from './plugins/vuetify';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

Vue.use(Toast, {
  transition: 'Vue-Toastification__fade',
  transitionDuration: 250,
  position: 'bottom-center',
  hideProgressBar: false,
  maxToasts: 5,
} as PluginOptions);

Vue.prototype.$webrtc = adapter;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
