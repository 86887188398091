import * as THREE from 'three';
import AntMediaPlayer from './AntMediaPlayer';

export default class SphericalPlayer extends AntMediaPlayer {
  /* ============================================================================
     ========================= PROTECTED CONCRETE METHOD ========================
     ============================================================================
  */
  /**
   * initThreeJSScene()
   * Initialize the spherical video representation within the THREE.JS scene.
   * @param scene THREE.JS scene object
   */
  protected initThreeJSScene(scene: THREE.Scene): void {
    const texture = new THREE.VideoTexture(this.html5VideoElement);
    const geometry = new THREE.SphereGeometry(500, 60, 40);
    // invert the geometry on the x-axis so that all of the faces point inward
    geometry.scale(-1, 1, 1);
    // Map the video texture over a mesh material
    const material = new THREE.MeshBasicMaterial({ map: texture });
    const mesh = new THREE.Mesh(geometry, material);
    mesh.rotation.y = this.setRotation(-80); // Or -260 when the camera display is facing the front of the vehicle
    scene.add(mesh);
  }

  /**
   * setRotation()
   * Translates degrees to the THREE.JS used radian format
   * @param degrees the amount of degrees in which something needs to be rotated
   * @returns the amount of degrees expressed in radians
   */
  private setRotation(degrees: number): number {
    return ((Math.PI * 2) / 360) * degrees;
  }
}
