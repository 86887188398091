import * as THREE from 'three';
import AntMediaPlayer from './AntMediaPlayer';

export default class OverlayPlayer extends AntMediaPlayer {
  /* ============================================================================
     ========================= PROTECTED CONCRETE METHOD ========================
     ============================================================================
  */
  /**
   * initThreeJSScene()
   * Initialize the overlay video representation within the THREE.JS scene.
   * @param scene THREE.JS scene object
   */
  protected initThreeJSScene(scene: THREE.Scene): void {
    const thermalTexture = new THREE.VideoTexture(this.html5VideoElement);

    const planeGeometry = new THREE.PlaneGeometry(5, 5);
    const planeMaterial = new THREE.MeshBasicMaterial({
      map: thermalTexture,
    });
    const plane = new THREE.Mesh(planeGeometry, planeMaterial);
    plane.position.set(5, 0, -10);
    plane.geometry.scale(-1, 1, 1);
    plane.rotateOnAxis(new THREE.Vector3(0, 1, 0), this.setRotation(170));
    scene.add(plane);
  }

  public setRotation(degrees: number): number {
    return ((Math.PI * 2) / 360) * degrees;
  }
}
