








import Vue from 'vue';

export default Vue.extend({
  name: 'AuthLayout',
  mounted() {
    // this.$router.push({ name: 'Vr' });
  },
});
