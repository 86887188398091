




























import Vue from 'vue';

import ThreeXR from '@/common/three-xr';

export default Vue.extend({
  name: 'Vr',
  data() {
    return {
      threeXR: undefined as unknown as ThreeXR,
    };
  },
  mounted() {
    this.threeXR = new ThreeXR();
  },
});
