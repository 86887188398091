// eslint-disable-next-line @typescript-eslint/no-var-requires
import { WebRTCAdaptor } from '@antmedia/webrtc_adaptor';

export default abstract class AntMediaPlayer {
  private readonly RETRY_INTERVAL = 1000 * 5; // 5 seconds

  private webSocketURL: string;

  private streamId: string;

  private webRTCAdaptor: any;

  private loaderElement: HTMLElement | undefined;

  private webrtcConfiguration = {
    iceServers: [{ urls: 'stun:stun.l.google.com:19302' }],
  } as RTCConfiguration;

  protected html5VideoElement: HTMLVideoElement;

  constructor(
    scene: THREE.Scene,
    videoElement: HTMLVideoElement,
    hostname: string,
    port: number,
    streamId: string,
    path: string,
    secure = false,
    loaderElement?: HTMLElement,
    configuration?: RTCConfiguration
  ) {
    this.webSocketURL = `${secure ? 'wss' : 'ws'}://${hostname}:${port}/WebRTCAppEE/websocket`;
    this.loaderElement = loaderElement;

    if (configuration) {
      this.webrtcConfiguration = configuration;
    }

    this.html5VideoElement = videoElement;
    videoElement.play();
    this.streamId = streamId;

    // Listen to the actual videoplayer events to make sure that the user can see the first frame
    videoElement.addEventListener('loadeddata', (event) => {
      this.setLoaderVisibility(false);
    });

    this.webRTCAdaptor = new WebRTCAdaptor({
      websocket_url: this.webSocketURL,
      mediaConstraints: {
        video: false,
        audio: false,
      },
      peerconnection_config: this.webrtcConfiguration,
      sdp_constraints: {
        OfferToReceiveAudio: true,
        OfferToReceiveVideo: true,
      },
      remoteVideoId: this.html5VideoElement.id,
      bandwidth: 'unlimited',
      dataChannelEnabled: false,
      isPlayMode: true,
      callback: this.playerCallback.bind(this),
      callbackError: this.playerError.bind(this),
    });

    // === Three.JS ===
    // Set scene references
    this.initThreeJSScene(scene);
  }

  private playerCallback(info: any, obj: any): void {
    // TODO - Implement informational messages
    if (info === 'initialized') {
      console.log('initialized');
      this.webRTCAdaptor.play(this.streamId, '', '', [], '', '');
    } else if (info === 'play_started') {
      // joined the stream
      console.log('play started');
      this.webRTCAdaptor.getStreamInfo(this.streamId);
      this.webRTCAdaptor.enableStats(obj.streamId);

      // TODO - For later use
      // setInterval(() => {
      //   console.log(this.webRTCAdaptor.remotePeerConnectionStats[this.streamId]);
      // }, 5000);
    } else if (info === 'play_finished') {
      console.log('play finished');
      this.setLoaderVisibility(true);
      this.startRetrying();
    }
  }

  private playerError(error: any, message: any): void {
    console.log(error);
    if (error === 'no_stream_exist') {
      this.startRetrying();
    }
  }

  private startRetrying(): void {
    console.log(
      `No stream found with id ${this.streamId}, retrying in ${
        this.RETRY_INTERVAL / 1000
      } seconds...`
    );
    setTimeout(() => {
      this.webRTCAdaptor.play(this.streamId, '', '', [], '', '');
    }, this.RETRY_INTERVAL);
  }

  private setLoaderVisibility(show: boolean): void {
    if (this.loaderElement) {
      this.loaderElement.style.display = show ? 'block' : 'none';
    }
  }

  /* ============================================================================
     ============================= ABSTRACT  METHODS ============================
     ============================================================================
  */
  /**
   * PROTECTED ABSTRACT initThreeJSScene()
   * Provide means for a concrete implementation to setup required THREE.JS variables.
   */
  protected abstract initThreeJSScene(scene: THREE.Scene): void;
}
