






import Vue from 'vue';
import AuthLayout from '@/components/layout/AuthLayout.vue';
import UnauthLayout from '@/components/layout/UnauthLayout.vue';

export default Vue.extend({
  name: 'App',
  components: {
    AuthLayout,
    UnauthLayout,
  },

  computed: {
    layout(): string {
      return this.$store.getters.getLoggedState === true ? 'AuthLayout' : 'UnauthLayout';
    },
  },
});
